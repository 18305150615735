.singlepage {
  font-size: 16px;
  color: #333;
  padding: 50px;
  h1 {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 40px;
  }
  h2 {
    font-weight: 700;
    margin: 20px 0;
  }
  p {
    line-height: 2;
    text-indent: 2em;
  }
  ul {
    list-style: lower-roman inside;
  }
  ul li {
    text-indent: 2em;
    line-height: 2;
  }
  @media (max-width: 1080px) {
    padding: 5vw 8vw 20vw;
    font-size: 3.733vw;
  }
}
